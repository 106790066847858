//Toggles hamburger menu
function toggleMenu() {
    $('.mobile-button').toggle();
    $('.mobile-close').toggle();

    var hidden = $('.mobile-nav-menu').css("display") === "none";
    if (hidden)
        $('.mobile-nav-menu').css({
            'display': 'flex',
            'z-index': '999'
        });
    else
        $('.mobile-nav-menu').hide();
}
function toggleSubNav() {
    $('.subNav').toggle();
}

//Adds style on active header navigation
$(function() {
    $("#header .navbar-nav a").each(function() {
        if (this.href === window.location) {
            $(this).addClass('selected');
        };
    });
    //Required for dynamic urls, 'settings' folder (i.e. on RFP page, if included)
    $('#applicantLoginLink').attr('href',settings.Url)
});

function switchVisible(divId) {
    $('.switch-content').each(function (i, section) {
        if(section.id === divId) {
            section.style.display = 'flex';
        } else {
            section.style.display = 'none';
        }
    })
}

$(function() {
    // this will get the full URL at the address bar
    var url = window.location.href;

    // passes on every "a" tag
    $(".navbar-nav a").each(function() {
        // checks if its the same on the address bar
        if (url === (this.href)) {
            $(this).closest("a").addClass("active");
            $(this).siblings(".subNav").show();

            //for making parent of submenu active
            $(this).closest("a").parent().parent().children().addClass("active");
        }
    });

    $("#year")[0].innerText = new Date().getFullYear();
});


// Contact Us Form
// Contact Us Form
function createEmail(e) {
    console.log(e);
    $(e.sender).attr('disabled', true);

    // Validate form fields
    var name = $('#Name').val().trim();
    var email = $('#Email').val().trim();
    var phone = $('#Phone').val().trim();
    var message = $('#Message').val().trim();
    var recaptcha = grecaptcha.getResponse();

    if (!name || !email || !phone || !message || !recaptcha) {
        // Show error message if any field is empty
        $('.contact-form-error').show().text('All fields are required.');
        $(e.sender).attr('disabled', false);
        return;
    }

    var funcParams = {
        Name: name,
        Email: email,
        Phone: phone,
        Message: message,
        'g-recaptcha-response': recaptcha
    }

    var jsonData = funcParams;

    $.ajax({
        url: settings.ContactUrl + 'Contact/CreateForm/',
        data: jsonData,
        type: 'POST',
        success: function (data) {
            if (data.success) {
                $('#SubmitBtn').attr('disabled', true);
                $('#ContactFormWrapper').empty().html('Thank you for contacting us!').css({ 'font-size': '28px', 'margin-top': '20px' });
                $('.contact-form-error').hide();
            } else {
                $('.contact-form-error').show().text('Error, please retry sending form.');
            }
        },
        error: function (e) {
            $('.contact-form-error').show().text('Error, please retry sending form.');
        }
    });
}
